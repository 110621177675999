/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "basic-template-for-competitive-programming-in-rust",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#basic-template-for-competitive-programming-in-rust",
    "aria-label": "basic template for competitive programming in rust permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Basic Template for Competitive Programming in Rust"), "\n", React.createElement(_components.p, null, "includes Input functions for various data types necessary for Competitive Programming in Rust"), "\n", React.createElement(_components.h2, {
    id: "introduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction",
    "aria-label": "introduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "This article is specifically for people that are starting Competitive Programming in Rust")), "\n", React.createElement(_components.p, null, "In this article, we will put together the programs written in previous articles to make a nicely designed and simple template for Competitive Programming in Rust for speedy submissions."), "\n", React.createElement(_components.p, null, "Feel free to use it. If you like it, you can ", React.createElement(_components.a, {
    href: "https://github.com/namanlp"
  }, "follow me on GitHub"), " . If you find any issue, ", React.createElement(_components.a, {
    href: "https://github.com/namanlp/rustp"
  }, "Report the issue here"), "."), "\n", React.createElement(_components.p, null, "I hope you like it!"), "\n", React.createElement(_components.h2, {
    id: "basic-io-template",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#basic-io-template",
    "aria-label": "basic io template permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Basic I/O Template"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "/*\nThis template is made by Naman Garg <naman.rustp@gmail.com>\nGitHub : https://github.com/namanlp\nGitLab : https://gitlab.com/namanlp\nWebsite : https://rustp.org\n\nYou can visit https://rustp.org/basic-programs/basic-template/\nfor understanding the template\n\nFeel free to copy the template, but not the solutions :D\nThank You\n */\n\n#![allow(unused)]\n\nuse std::io::stdin;\n\nfn take_int() -> usize {\n    let mut input = String::new();\n    stdin().read_line(&mut input).unwrap();\n    return input.trim().parse().unwrap()\n}\n\nfn take_vector() -> Vec<usize> {\n    let mut input = String::new();\n    stdin().read_line(&mut input).unwrap();\n    let arr: Vec<usize> = input.trim().split_whitespace().map(|x| x.parse().unwrap()).collect();\n    return arr;\n}\n\nfn take_string() -> Vec<char> {\n    let mut input = String::new();\n    stdin().read_line(&mut input).unwrap();\n    let vec:Vec<char> = input.trim().chars().collect();\n    return vec;\n}\nfn to_string(vec:Vec<char>) -> String{return vec.iter().collect::<String>();}\n\nfn solve() {\n// ======================= Code Here =========================\n}\n\n\npub fn main() {\n    let t = take_int();\n    for _ in 0..t { solve(); }\n}\n")), "\n", React.createElement(_components.p, null, "You can take the input of space separated numbers as vector of numbers, and then access the individual numbers using indexing, that is, square brackets ", React.createElement(_components.code, null, "[]"), "."), "\n", React.createElement(_components.p, null, "I personally prefer this instead of making scanner functions, simply because they are more handy."), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "In this article, I shared a basic template for input and output for simple competitive programming questions in Rust. This template might be updated in the future."), "\n", React.createElement(_components.p, null, "Next, we should practice some competitive programming questions using Rust for better understanding."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Basic Template for Competitive Programming in Rust",
    description: "This article contains basic template for input and output for simple competitive programming questions in Rust for speedy submissions"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
